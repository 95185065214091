<template>
  <van-popup
    :value="showDialog"
    @input="handleShowDialogInput"
    position="bottom"
    @opened="handlePopupOpened"
  >
    <div
      :class="{
        commentToolDialogContent: true,
        commentWrap: false,
        commentToolDialogContentIos: iosShow,
      }"
    >
      <div class="textareaView">
        <textarea
          placeholder="说说你的想法"
          :value="value"
          @input="handleInput"
          ref="textarea"
          @focus="handleFocus"
          maxlength="5000"
        />
      </div>
      <div v-if="attList && attList.length > 0" class="attList">
        <van-tag
          v-for="(item, index) in attList"
          :key="index"
          closeable
          size="medium"
          color="#FAF2F2"
          round
          @close="handleDeleteAtt(index)"
        >
          @{{ item.attName }}
        </van-tag>
      </div>

      <editTopicToolbar
        showAnonymousCommentCheckBox
        showPublishBtn
        @publish="handlePublish"
        :iosMarginBottom="iosMarginBottom"
        :hideRelation="hideRelation"
        :hideImg="hideImg"
        :is-anonymous-comment="isAnonymousComment"
        :isComment="true"
        @update:isAnonymousComment="handleIsAnonymousCommentUpdate"
      />
    </div>
  </van-popup>
</template>

<script>
import editTopicToolbar from "@/components/editTopicToolbar";

export default {
  props: {
    showDialog: Boolean,
    value: String,
    iosMarginBottom: Boolean,
    isAnonymousComment: Boolean,
    hideRelation: Boolean,
    hideImg: Boolean,
    attList: Array,
  },
  data() {
    return {
      iosShow: false,
    };
  },
  components: {
    editTopicToolbar,
  },

  // computed:{
  //   ...mapGetters([
  //     'isAndroid'
  //   ])
  // },
  created() {
    this.appSource();
  },
  mounted() {},
  methods: {
    // 判断是不是ios
    appSource() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        console.info("ios");
        this.iosShow = true;
        // return "ios";
      } else {
        console.info("andriod");
        this.iosShow = false;
        // return "andriod";
      }
    },
    handlePopupOpened() {
      this.$refs.textarea.focus();
    },
    handleShowDialogInput(val) {
      this.$emit("update:showDialog", val);
    },
    handlePublish() {
      this.$emit("publish");
      this.$refs.textarea.blur()
    },
    handleInput(e) {
      const { value } = e.target;
      this.$emit("input", value);
    },
    handleIsAnonymousCommentUpdate(val) {
      this.$emit("update:isAnonymousComment", val);
    },
    handleFocus() {
      this.$emit("focus");
    },
    handleDeleteAtt(index) {
      this.$emit("delete", index);
      setTimeout(() => {
        let str = "";
        this.attList.forEach((item) => {
          str = str + `${item.attId}:${item.attName}` + ",";
        });
        if (str.endsWith(",")) {
          str = str.substring(0, str.length - 1);
        }
        this.$route.query.selectMembers = str;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.commentToolDialogContentWrap {
  position: fixed;
}
.commentWrap {
  background-color: #fff;
  padding-bottom: 300px;
}
.commentToolDialogContentIos {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.commentToolDialogContent {
  .textareaView {
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;

    textarea {
      flex: 1;
      padding: 10px;
      background-color: $whiteGray;
      border: none;
      outline: none;
      resize: none;
      font-size: 14px;
    }
  }

  .attList {
    padding: 0 20px 5px 20px;
    line-height: 10px;

    .van-tag {
      color: $mainColor;
      margin-right: 3px;
    }
  }
}
</style>
