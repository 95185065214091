<template>
  <div
    @click="cancelDefaultEvent($event)"
    :class="{ editTopicToolbarView: true, iosMarginBottom: iosMarginBottom }"
  >
    <div class="editTopicToolbarView_left">
      <van-checkbox
        @mousedown.native="handleMousedown($event)"
        v-if="showAnonymousCommentCheckBox"
        :value="isAnonymousComment"
        @input="handleCheckBoxInput"
        shape="round"
        class="checkbox"
        checked-color="#FE5800"
        icon-size="16px"
      >匿名发声
      </van-checkbox>
      <!--todo @，#，视频，图片-->
      <div class="operate-img">
        <img
          v-for="item in getOptions"
          :key="item.key"
          :src="item.iconUrl"
          class="icon"
          @click="handleToolClick($event, item.key)"
          @mousedown.stop="handleMousedown($event, item.key)"
        />
      </div>
    </div>
    <div
      class="editTopicToolbarView_right"
      v-if="showPublishBtn">
      <p
        @mousedown.stop="handleMousedown($event)"
        @click="handlePublish($event)"
        class="publishBtn">
        {{ buttonText }}
      </p>
    </div>

    <!-- todo   视频loading-->
    <van-dialog
      style="background-color: transparent;"
      v-model="isLoadingVideo"
      :show-confirm-button="false"
      :show-cancel-button="false">
      <div class="flex-center-row loading-text">loading...</div>
      <van-progress
        :stroke-width="14"
        :percentage="percentage"
        pivot-text=""
        pivot-color="#FE5800"
        track-color="transparent"
        color="#FE5800"
      />
    </van-dialog>
    <!-- todo   图片loading-->
    <van-popup v-model="showLoading" :close-on-click-overlay='false'>
      <van-loading size="80px" />
    </van-popup>
  </div>
</template>

<script>
import {selectFile} from '@/utils/selectImg'

export default {
  props: {
    showAnonymousCommentCheckBox: Boolean,
    showPublishBtn: Boolean,
    isAnonymousComment: Boolean,
    hideRelation: Boolean,
    hideImg: Boolean,
    iosMarginBottom: Boolean,
    videoCount: Number,
    imageContent: Number,
    isComment: {
      type: Boolean,
      default: () => false
    },
    showTopic: {
      type: Boolean,
      default: () => false
    },
    showVideo: {
      type: Boolean,
      default: () => false
    },
    buttonText: {
      type: String,
      default: '发布'
    },
    showToup: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    const initIconUrl = (iconName) => {
      return `/images/editTopicToolbar/${iconName}`
    }
    return {
      options: [
        {key: 'relation', iconUrl: initIconUrl('relation.png')},
        {key: 'topic', iconUrl: initIconUrl('topic.png')},
        {key: 'img', iconUrl: initIconUrl('img.png')},
        {key: 'video', iconUrl: initIconUrl('video.png')},
        {key: 'toup', iconUrl: initIconUrl('Slice_90.svg')}
        // {key: 'face', iconUrl: initIconUrl('face.png')},
      ],
      showLoading: false, // 图片
      percentage: 0, // 视频上传进度
      isLoadingVideo: false, // 视频
      idLoadedVideo: false, // 视频上传完毕
      timeoutId: null
    }
  },
  computed: {
    getOptions () {
      const {options, hideRelation, hideImg, showTopic, showVideo, showToup} =
        this
      return options
        .filter((item) => item.key !== 'relation' || !hideRelation)
        .filter((item) => item.key !== 'img' || !hideImg)
        .filter((item) => item.key !== 'topic' || showTopic)
        .filter((item) => item.key !== 'video' || showVideo)
        .filter((item) => item.key !== 'toup' || showToup)
    }
  },
  methods: {
    cancelDefaultEvent (e) {
      console.log(e)
    },
    handleMousedown (e, key) {
      switch (key) {
        case 'relation': {
          return
        }
        case 'img': {
          return
        }
        case 'video': {
          return
        }
      }
      return e.preventDefault()
    },
    async handleToolClick (e, key) {
      switch (key) {
        case 'img': {
          if (this.videoCount > 0) {
            return this.$toast.fail({
              message: '不能同时发送图片和视频',
              position: 'top'
            })
          }
          const files = await selectFile(false) || []
          if(files.length > 9 - this.imageContent){
            this.$toast.fail({
              message: '图片不能超过9张',
              position: 'top'
            })
            return
          }
          this.showLoading = true
          const filesRes = await this.$upload.multipleFilesUpload(files)
          this.showLoading = false
          this.$emit(
            'img',
            filesRes.map((item) => item.filename)
          )
          break
        }
        case 'relation': {
          if (this.isComment) {
            const {
              name: fromRouteName,
              query: {selectMembers, id, tag, quoteId}
            } = this.$route
            return this.$router.push({
              name: 'organization',
              query: {fromRouteName, selectMembers, id, tag, quoteId}
            })
          } else {
            this.$emit('relation')
            break
          }
        }
        case 'video': {
          if (this.imageContent > 0) {
            return this.$toast.fail({
              message: '不能同时发送图片和视频',
              position: 'top'
            })
          }
          if (this.videoCount > 0) {
            return this.$toast.fail({
              message: '只能发布一个视频！',
              position: 'top'
            })
          }
          const files = await selectFile(false, 'video/*')

          try {
            this.isLoadingVideo = true
            this.idLoadedVideo = false
            this.percentage = 1
            this.handleAddPercentage()
            const filesRes = await this.$upload.multipleFilesUpload(files)
            this.$emit(
              'img',
              filesRes.map((item) => item.filename)
            )
          } finally {
            this.idLoadedVideo = true
          }
          break
        }
        case 'topic': {
          this.$emit('topic')
          break
        }
        case 'toup': {
          // 添加投票
          this.$emit('toup')
        }
      }
    },
    // 进度条递增
    handleAddPercentage () {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        if (this.idLoadedVideo) {
          this.percentage = 100
          setTimeout(() => {
            this.isLoadingVideo = false
          }, 300)
        } else {
          if (this.percentage < 99) {
            this.percentage++
          }
          this.handleAddPercentage()
        }
      }, 500)
    },
    handlePublish () {
      this.$emit('publish')
    },
    handleCheckBoxInput (value) {
      this.$emit('update:isAnonymousComment', value)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .van-dialog, .van-dialog__content {
    background-color: transparent;
  }

  .van-progress {
    border: 1px solid #FE5800;
    border-radius: 10px;
    margin: 0 2px;
    padding-bottom: 4px;
    //box-shadow: 0 0 5px #FE5800;
  }
}

.loading-text{
  font-size: 14px;
  margin-bottom: 10px;
  color: #FE5800;
}

.iosMarginBottom {
  margin-bottom: 60px;
}

.editTopicToolbarView {
  border-top: solid #f4f4f4 1px;
  padding: 0 14px 0 24px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s;

  &_left {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 0 -12px;

    .icon {
      height: 18px;
      margin: 0 8px;
    }

    .checkbox {
      font-size: 14px;
      margin: 0 12px;

      // ::v-deep .van-checkbox__label {
      // color: #BFBFBF !important;
      // }
    }

    .operate-img {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &_right {
    width: 23%;
    display: flex;
    justify-content: flex-end;
  }

  .publishBtn {
    color: white;
    background-color: $mainColor;
    height: 28px;
    width: 64px;
    border-radius: 15px;
    text-align: center;
    line-height: 28px;
    font-size: 12px;
  }

  .van-popup {
    overflow: hidden;
    background-color: transparent;
  }
}
</style>
